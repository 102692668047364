@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: 'Heebo', sans-serif;
  color: #09102a;
}

h1 {
  font-size: 32px;
  font-weight: 500;
}

h2 {
  font-size: 20px;
  font-weight: 500;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

span {
  color: #1d3561;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  font-family: 'Heebo', sans-serif;
}

